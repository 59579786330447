import React from "react"

const IconDolphinStarboard = ({ theme }) => {
  if (!theme) {
    theme = "#00558b"
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.89 85.72" width="50px">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill={theme} d="M90.12,63.48c.49-1,.85-1.9,1.36-2.73a15.41,15.41,0,0,0,2-5c.18-.89.44-1.76.54-2.66a7.8,7.8,0,0,0-.75-4.79A4.92,4.92,0,0,1,93,47.06c-.84,0-1.7,0-2.55-.08-2.21-.11-4.42-.2-6.62-.36-3.73-.26-7.45,0-11.17.12-2.6.06-5.18.37-7.76.63a56.37,56.37,0,0,0-8.21,1.3C52.06,49.84,47.4,50.92,43.06,53a50.72,50.72,0,0,0-9.55,5.78c-.41.32-.77.7-1.15,1a.78.78,0,0,0-.15,1c.25.46.46.94.67,1.41a17,17,0,0,1,.76,11.44c-.93,3.92-3.22,7-5.79,9.86-.62.7-1.26,1.38-2,2.14-.12-1.79-.25-3.45-.34-5.11a32.21,32.21,0,0,0-2.61-10.3,14.53,14.53,0,0,1-1.57-5.59c0-.47-.39-.59-.83-.35a18.5,18.5,0,0,1-1.83.93c-1.92.79-3.87,1.52-5.8,2.29a44.09,44.09,0,0,1-9.62,2.17c-1.06.17-2.11.41-3.29.64a7.33,7.33,0,0,1,.33-.78,41.36,41.36,0,0,1,4.16-6.7,27.82,27.82,0,0,1,5.82-5.33c1.46-1,2.83-2.13,4.31-3.11a17.79,17.79,0,0,1,6.83-2.73c1.66-.28,3.27-.8,4.91-1.22A3.1,3.1,0,0,0,28,49.18c1.29-1.62,2.7-3.15,4.07-4.71,1.5-1.73,3-3.52,4.52-5.19s3.29-3.45,5-5.05c2.05-1.86,4.17-3.65,6.36-5.34,3.79-2.95,7.59-5.92,12-8,1.56-.75,3.14-1.46,4.71-2.18a1.78,1.78,0,0,0,1.16-1.65,10.59,10.59,0,0,0-.52-4.34,29.72,29.72,0,0,0-1.35-3.66,35.66,35.66,0,0,0-2.18-3.66c-.52-.8-1.15-1.53-1.72-2.29a3.16,3.16,0,0,1-.33-.56C59.05,1,59.23.46,60.83.13A4,4,0,0,1,62.37,0a13.28,13.28,0,0,1,4.45,1.39,59.26,59.26,0,0,1,7.72,5.06,68.27,68.27,0,0,1,6.68,6.16,1.53,1.53,0,0,0,1.64.4c1.6-.37,3.2-.78,4.83-1,4.15-.65,8.3-1.24,12.5-1.5,2.76-.16,5.53-.46,8.29-.46,2.59,0,5.17.34,7.76.54,2.07.16,4.14.25,6.2.53a33.43,33.43,0,0,1,4.94,1.06,74.43,74.43,0,0,1,7.25,2.45,29.48,29.48,0,0,1,7.94,4.86,14.15,14.15,0,0,1,4.19,7.05c.21.7.28,1.44.52,2.13a1.87,1.87,0,0,0,.72.86c1.14.8,2.3,1.55,3.46,2.32a13.57,13.57,0,0,1,4.67,4.43,7.25,7.25,0,0,1,.73,2.19A1.22,1.22,0,0,1,155.8,40a6.15,6.15,0,0,1-1.42.12c-2.82,0-5.64-.1-8.45.07s-5.77.57-8.65,1c-4.55.63-9.09,1.32-13.62,2Q118.4,44,113.16,45c-1.23.24-2.41.72-3.61,1.07-.62.18-.7.69-.84,1.2a19.57,19.57,0,0,1-3.32,7,25.91,25.91,0,0,1-7.48,6.58c-1.55.86-3.14,1.66-4.75,2.41A3.89,3.89,0,0,1,90.12,63.48Z"/>
        </g>
      </g>
    </svg>
  )
}

export default IconDolphinStarboard