import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Venture = ({ name, url, logoURL }) => {
  let theme
  if (name === "Brontosaurus Games") {
    theme = "#53b3b1"
  } else if (name === "Arch.to") {
    theme = "#000000"
  } else if (name === "Green Dynasty Architect & Builder") {
    theme = "#006633"
  } else {
    theme = "#00558b"
  }

  return (
    <div key={name}>
      <AniLink
        className="company-logo"
        to='/ventures'
        title="Ventures"
        paintDrip
        duration={1}
        theme={theme}
      >
        <img src={logoURL} alt={name} />
      </AniLink>
    </div>
  )
}

export default Venture