import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import IconDolphinPort from "./IconDolphinPort"
import IconDolphinStarboard from "./IconDolphinStarboard"

const IslandNav = ({ category, categoryLabel, prevProject, nextProject, theme }) => {

  let returnLabel = categoryLabel + "s";
  if (categoryLabel === "Article" || categoryLabel === "Presentation") {
    returnLabel = "Blog";
  }

  let themeOverride

  if (theme) {
    themeOverride = {
      color: theme,
    }
  }

  return (
    <div className="island-nav">
      <div className="island-nav-btn-wrap next-project-wrap">
        {prevProject &&
          <AniLink
            swipe
            direction="right"
            hex="#00558b"
            to={`/${category}/${prevProject.slug}`}
            className="next"
            title={`Previous ${categoryLabel}: ${prevProject.title}`}
            aria-label={`Previous ${categoryLabel}: ${prevProject.title}`}
          >
            {/* <div>{nextProject.title}</div> */}
            <IconDolphinPort theme={theme} />
            <span style={themeOverride}> Previous {categoryLabel}</span>
          </AniLink>
        }
      </div>
      <div className="island-nav-btn-wrap return-to-wrap">
        <AniLink
          fade
          to={`/${category}`}
          className="return-to"
          style={themeOverride}
        >
          Return to {returnLabel}
        </AniLink>
      </div>
      <div className="island-nav-btn-wrap prev-project-wrap">
        {nextProject &&
          <AniLink
            swipe
            direction="left"
            hex="#00558b"
            to={`/${category}/${nextProject.slug}`}
            className="prev"
            title={`Next ${categoryLabel}: ${nextProject.title}`}
            aria-label={`Next ${categoryLabel}: ${nextProject.title}`}
            style={themeOverride}
          >
            <span style={themeOverride}>Next {categoryLabel} </span>
            <IconDolphinStarboard theme={theme} />
            {/* <div>{prevProject.title}</div> */}
          </AniLink>
        }
      </div>

    </div>
  )
}

export default IslandNav





