import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
import ReactMarkdown from "react-markdown"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import Layout from "@components/layout"
import Footer from "@components/Footer/Footer"
import SEO from "@components/seo"
import IslandNav from "@components/Nav/IslandNav"
import SailForth from "@components/Nav/SailForth"
// import DownArrow from "@static/icons/down-arrow.svg"
import Quote from "@components/Quotes/Quote"
import Tool from "@components/Nav/Tool"
import Company from "@components/Nav/Company"
import Venture from "@components/Nav/Venture"
// import { getColorLuminance, shadeColor, colorShade } from "@utils"
import VideoPlayer from "@components/VideoPlayer"

export const query = graphql`
  query ProjectQuery($id: Int) {
    strapiProject(strapiId: { eq: $id }) {
      strapiId
      title
      description
      content
      heroURL
      url
      titleshort
      theme
      date
      showdate
      videoplaylistyoutubeid
      videos {
        title
        posterURL
        mp4URL
        webmURL
      }
      companies {
        name
        url
        logoURL
        Type
      }
      testimonials {
        name
        url
        testimonial
      }
      tools {
        name
        url
        logoURL
      }
    }
  }
`

const Project = ({ data, pageContext }) => {
  const project = data.strapiProject
  const context = pageContext

  const next = context.next ? context.next : null
  const prev = context.prev ? context.prev : null

  const title = project.title ? project.title : ""
  const description = project.description ? project.description : ""
  const content = project.content ? project.content : ""
  // console.log("content: ", content)
  const date = project.date ? project.date : null
  const showdate = project.showdate ? project.showdate : false
  const heroURL = project.heroURL ? project.heroURL : ""
  const url = project.url ? project.url : ""
  const titleshort = project.titleshort ? project.titleshort : title
  const theme = project.theme ? project.theme : ""

  // const videoSingle = project.videoyoutubeid ? project.videoyoutubeid : ""
  const videoPlaylist = project.videoplaylistyoutubeid ? project.videoplaylistyoutubeid : ""

  const videos = project.videos

  const companies = project.companies
  const testimonials = project.testimonials
  const tools = project.tools

  let agencies = []
  let clients = []
  let ventures = []

  if (companies) {
    companies.map(item => {
      switch (item.Type) {
        case 'Agency':
          const agency = (
            <Company
              key={item.name}
              name={item.name}
              url={item.url}
              logoURL={item.logoURL}
            />
          )
          // console.log('agency: ', agency)
          agencies.push(agency)
          break

        case 'Client':
          const client = (
            <Company
              key={item.name}
              name={item.name}
              url={item.url}
              logoURL={item.logoURL}
            />
          )
          // console.log('client: ', client)
          clients.push(client)
          break

        case 'Venture':
          const venture = (
            <Venture
              key={item.name}
              name={item.name}
              url={item.url}
              logoURL={item.logoURL}
            />
          )
          // console.log('venture: ', venture)
          ventures.push(venture)
          break

        default:
      }
    })
  }

  let clientsLabel
  let agenciesLabel
  let venturesLabel

  if (clients.length > 1) {
    clientsLabel = "Clients:"
  } else if (clients.length === 1) {
    clientsLabel = "Client:"
  }

  if (agencies.length > 1) {
    agenciesLabel = "Agencies:"
  } else if (agencies.length === 1) {
    agenciesLabel = "Agency:"
  }

  if (ventures.length > 1) {
    venturesLabel = "Ventures:"
  } else if (ventures.length === 1) {
    venturesLabel = "Venture:"
  }



    // companies = (
    //   <div className="toolkit">
    //     <h4>In Collaboration With</h4>
    //     // <IconLinkGroup
    //     //   type="companies"
    //     //   icons={data.strapiProject.companies}
    //     // />
    //   </div>
    // );


  let toolkit = []

  if (tools) {
    tools.map(item => {
      const tool = (
        <Tool
          key={item.name}
          name={item.name}
          url={item.url}
          logoURL={item.logoURL}
        />
      )
      // console.log('tool: ', tool)
      toolkit.push(tool)
    })
  }

  let testimonialsList = []

  if (testimonials) {
    testimonials.map(item => {
      const testimonialQuote = (
        <Quote
          key={item.name}
          author={item.name}
          quote={item.testimonial}
          url={item.url}
        />
      )
      testimonialsList.push(testimonialQuote)
    })

  }

  const projectNav = (
    <IslandNav
      category="projects"
      categoryLabel="Project"
      nextProject={next}
      prevProject={prev}
      theme={theme}
    />
  );

  let externalNav;

  if (url) {
    externalNav = (
      <SailForth
        url={url}
        titleshort={titleshort}
        theme={theme}
      />
    )
  } else {
    externalNav = null
  }

  const heroAltText = title + " hero"

  // const YouTubeVideoPlayer = ({ video }) => (
  //   <div className="video embed-container">
  //     <iframe
  //       width="1600"
  //       height="900"
  //       frameBorder="0"
  //       title="YouTube video player"
  //       src={`https://www.youtube.com/embed/${video}`}
  //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  //       allowFullScreen
  //     />
  //   </div>
  // )
  //
  // let singleVideoPlayer
  //
  // if (videoSingle) {
  //   singleVideoPlayer = (
  //     <YouTubeVideoPlayer video={videoSingle} />
  //   )
  // }

  const YouTubeVideoPlaylistPlayer = ({ video }) => (
    <div className="video embed-container">
      <iframe
        width="1600"
        height="900"
        frameBorder="0"
        title="YouTube video player"
        src={`https://www.youtube.com/embed/videoseries?list=${video}`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  )

  let embeddedVideoPlaylist

  if (videoPlaylist) {
    embeddedVideoPlaylist = (
      <YouTubeVideoPlaylistPlayer video={videoPlaylist} />
    )
  }

  let hostedVideosList = []

  if (videos) {
    videos.map(item => {
      const video = (
        <VideoPlayer
          key={item.title}
          title={item.title}
          posterURL={item.posterURL}
          mp4URL={item.mp4URL}
          webmURL={item.webmURL}
        />
      )
      hostedVideosList.push(video)
    })
  }

  // console.log('hostedVideosList', hostedVideosList)

  const themedLinkStyle = {
    color: theme,
    // "&:hover": {
    //   color: colorShade(theme, 100)
    // },
  }

  // console.log('themedLinkStyle: ', themedLinkStyle)
  // console.log('theme: ' + theme)
  // console.log('hover 1: ' + getColorLuminance(theme, .50))
  // console.log('hover 2: ' + shadeColor(theme, 50))
  // console.log('hover 3: ' + colorShade(theme, 50))

  return (
    <Layout theme={theme}>
      <main className="grid-content">
        {heroURL &&
          <div className="project-hero">
            <img src={heroURL} alt={heroAltText} />
            {/*<div className="hero-down-arrow">
              <DownArrow />
            </div>*/}
          </div>
        }
        <div className="grid-content-inner">
          <div className="grid-container island page-template">
            <SEO
              title={title}
              description={description}
            />

            {/*<Img
              fluid={article.image.childImageSharp.fluid}
            />*/}

            {projectNav}
            <h1>{title}</h1>
            {date && showdate &&
              <h3>Date:  {date}</h3>
            }
            {!showdate &&
              <h3>WIP</h3>
            }

            {clients.length > 0 &&
              <div className="credits">
                <h4>{clientsLabel}</h4>
                <div className="companies-wrapper">
                  {clients}
                </div>
              </div>
            }
            {agencies.length > 0 &&
              <div className="credits">
                <h4>{agenciesLabel}</h4>
                <div className="companies-wrapper">
                  {agencies}
                </div>
              </div>
            }
            {ventures.length > 0 &&
              <div className="ventures-wrapper">
                <h4>{venturesLabel}</h4>
                <div className="companies-wrapper">
                  {ventures}
                </div>
              </div>
            }


            {toolkit &&
              <div className="toolkit">
                <h4>Toolkit:</h4>
                <div className="tools-wrapper">
                  {toolkit}
                </div>
              </div>
            }
            {hostedVideosList}
            {/*{singleVideoPlayer}*/}
            {embeddedVideoPlaylist}

            {testimonialsList.length > 1 &&
              <div className="testimonials-wrapper">
                <h4>Testimonials:</h4>
                {testimonialsList}
              </div>
            }
            {url && externalNav}
            <ReactMarkdown
              children={content}
              linkTarget="_blank"
              components={{
                a: ({node, ...props}) => <a style={themedLinkStyle} {...props} />
              }}
            />
            {url && externalNav}
            <br />
            <br />
            {projectNav}

          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  )
}

export default Project
