import React from "react"

const IconLargerShip = ({ theme }) => {
  if (!theme) {
    theme = "#00558b"
  }

  return (
    <svg version="1.1" width="175px" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 163 146.6" enableBackground="new 0 0 163 146.6" role="img">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill={theme} d="M87.09,97.87c1.13-.11,2.15-.21,3.18-.29s1.84-.16,2.75-.15c1.11,0,2.22.14,3.32.14,1.29,0,2.59-.11,3.88-.13,2,0,3.92-.07,5.88-.07,2.59,0,5.18.06,7.77.06h28.84c.66,0,1.32.1,2,.13.29,0,.57,0,1,0-.27-.5-.46-.93-.7-1.33-1.06-1.77-2.09-3.57-3.22-5.3-1.61-2.48-3.3-4.91-5-7.34-1.41-2-2.86-4-4.31-6-.65-.89-1.36-1.75-2-2.64-1-1.39-2.1-2.77-3.07-4.21-1.63-2.42-3.19-4.89-4.81-7.32-2.31-3.44-4.64-6.85-7-10.28-1.3-1.93-2.59-3.87-3.91-5.78-1.66-2.4-3.37-4.77-5-7.19-1.26-1.86-2.44-3.78-3.65-5.68-1-1.56-2-3.12-3-4.68s-1.94-3-2.92-4.44c-1.19-1.78-2.4-3.55-3.6-5.32-.16-.25-.34-.49-.49-.74A91.94,91.94,0,0,1,89,10.88C88.15,9,87.22,7.16,86.21,5a5.57,5.57,0,0,0-.1.94c0,1.26.15,2.51.19,3.77.06,1.55.07,3.1.09,4.66,0,3.17.12,6.34.11,9.52,0,3.87-.14,7.75-.17,11.63,0,1.74.13,3.47.19,5.21,0,1.07,0,2.14.07,3.21.06,2.92.15,5.84.16,8.76,0,1.7-.2,3.39-.22,5.09,0,2.51,0,5,0,7.54,0,.89,0,1.78,0,2.66,0,1.81.11,3.62.12,5.43,0,1.26-.11,2.51-.1,3.77,0,2.44.08,4.87.09,7.31,0,1.7-.1,3.4-.06,5.1s.19,3.61.3,5.41c0,.48.06,1,.08,1.44S87.08,97.36,87.09,97.87Z"/>
          <path fill={theme} d="M34.84,98.66a4.44,4.44,0,0,0,2.43.24c1.44,0,2.88-.15,4.32-.2,2.66-.09,5.32-.16,8-.23l9.75-.22L67.62,98c2.92-.07,5.84-.16,8.75-.21,1.3,0,2.59,0,3.88.06.15,0,.29,0,.51,0a3.76,3.76,0,0,0,.1-.73c-.07-1.44-.17-2.87-.22-4.31,0-1.21,0-2.43-.09-3.64-.06-1.44-.17-2.87-.26-4.31a2.94,2.94,0,0,1-.05-.66A19.42,19.42,0,0,0,80,80c-.09-1.14-.11-2.29-.09-3.44,0-2.47.14-5,.19-7.42,0-2.07,0-4.14.1-6.21,0-2.4.14-4.8.22-7.2.07-2.14.13-4.28.24-6.42.11-2.36.29-4.72.4-7.08s.19-5,.3-7.54c.07-1.65.18-3.3.27-4.95,0-.2,0-.41,0-.65a1.27,1.27,0,0,0-.94.64c-1.69,2.19-3.4,4.37-5.06,6.6-2,2.69-3.93,5.45-5.68,8.31-2.15,3.5-4.28,7-6.51,10.45S59,61.69,56.84,65c-1.25,1.87-2.49,3.75-3.74,5.63-2.16,3.22-4.35,6.43-6.48,9.67-2,3-3.87,6-5.8,9-1.1,1.71-2.21,3.41-3.31,5.11l-2.4,3.73C35,98.32,34.94,98.48,34.84,98.66Z"/>
          <path fill={theme} d="M83.66,2.34c-.07.61-.19,1.22-.21,1.83-.07,3.37-.13,6.73-.16,10.1,0,1.91,0,3.82.06,5.73,0,2.29.1,4.58.08,6.87,0,2.58-.11,5.16-.16,7.75,0,1.84,0,3.69-.07,5.54s-.1,3.54-.12,5.31c0,2.48,0,5,0,7.45,0,.69.12,1.39.1,2.09-.07,1.77-.19,3.54-.28,5.31-.06,1.29-.09,2.59-.12,3.88-.08,3.18-.11,6.36-.25,9.54-.19,4.09.06,8.18.12,12.26,0,2.25.12,4.5.2,6.75.14,3.77.2,7.54.49,11.3.15,1.92.1,3.84.16,5.75.1,3.51.26,7,.22,10.54,0,3.06-.15,6.12,0,9.19,0,1-.13,2-.17,3a6.84,6.84,0,0,0,.26,2.48h2.31c0-.29,0-.54,0-.78,0-2.37.05-4.73,0-7.1,0-1.85-.12-3.69-.16-5.54,0-1,0-2,0-3,0-1.73,0-3.47-.13-5.2-.12-2.91-.28-5.83-.43-8.75,0-.25,0-.51-.07-.77a15.35,15.35,0,0,1-.11-2.63,21.69,21.69,0,0,0-.12-3.32A11,11,0,0,1,85,95.06a5.45,5.45,0,0,0,0-1.21c-.09-2.77-.25-5.54-.27-8.31,0-3.44.06-6.88.09-10.33,0-2,0-3.92,0-5.88,0-3.32.13-6.64.12-10,0-1.88-.17-3.76-.24-5.64-.08-2.11-.16-4.21-.18-6.32,0-3.48,0-6.95,0-10.43,0-2.88,0-5.76-.12-8.63-.09-3.18-.31-6.35-.41-9.53a32,32,0,0,1,.11-6.42,7.08,7.08,0,0,0,.06-1.22c0-1.85,0-3.7,0-5.54,0-1.11-.17-2.2-.26-3.31Z"/>
          <path fill={theme} d="M67.11,6.45c-.34.06-.59.19-.69.12a3.13,3.13,0,0,0-2.56-.44,44.22,44.22,0,0,1-7.13.41,1.81,1.81,0,0,0,.83,1,20,20,0,0,0,6.55,3.62,19,19,0,0,0,4.33.85,7.09,7.09,0,0,0,1.22,0l5.52-.43,2.2-.18a12.44,12.44,0,0,1,4.55.79c.16-3.42.32-6.81.47-10.12a1.7,1.7,0,0,0-1.53-.74,58.24,58.24,0,0,0-6.05.21,50.67,50.67,0,0,1-5.65.05A38.4,38.4,0,0,1,62.24.93c-1-.19-1.93-.5-2.89-.74-.24-.06-.49-.1-.94-.19a6.55,6.55,0,0,0,.65.78,17.65,17.65,0,0,0,4.31,2.88A11.69,11.69,0,0,1,67.11,6.45Z"/>
          <path fill={theme} d="M125.22,110.07c-.11.45-.22.82-.28,1.21-.12.87-.21,1.74-.33,2.61a2,2,0,0,1-1.15,1.7c-1.13.58-2.19,1.29-3.34,1.84a21.29,21.29,0,0,1-4.81,1.62,15.8,15.8,0,0,0-2.18.55,32.89,32.89,0,0,1-5.18,1c-1.71.31-3.41.57-5.12.87a41.1,41.1,0,0,1-5.54.56c-2.34.09-4.69.15-7,.23l-5.28.2c-.42,0-.84.06-1.26,0-2-.08-4-.12-6-.29-1.68-.15-3.35-.47-5-.7-.61-.08-1.23-.09-1.85-.14a38.14,38.14,0,0,1-7.1-1.33q-2.84-.74-5.67-1.53a49.89,49.89,0,0,1-7.24-2.66A29.26,29.26,0,0,1,47,113.73a6.57,6.57,0,0,0-.75-.37c-.32-1.26,0-2.66-.57-4a6.43,6.43,0,0,0-.82-.09,40.46,40.46,0,0,1-6.3-.77c-1.13-.2-2.26-.32-3.38-.52-1.77-.31-3.53-.68-5.29-1-2-.35-3.93-.65-5.88-1-1.44-.27-2.87-.6-4.31-.88-2.11-.41-4.23-.82-6.35-1.21-1.4-.25-2.82-.46-4.24-.7l-.55-.09c0,.45.06.84.13,1.21a17.11,17.11,0,0,1,.38,3.49,8,8,0,0,0,.18,1.46c.28,1.7.54,3.41.87,5.11s.67,3.53,1.18,5.25a6.29,6.29,0,0,0,3.23,4.11l3.09,1.48a5.71,5.71,0,0,1,2.09,1.56c1,1.17,2,2.27,2.89,3.45,1.33,1.74,2.58,3.53,3.89,5.28.72,1,1.51,1.88,2.26,2.83.16.2.31.42.47.62.88,1.13,1.7,2.31,2.67,3.36a4.68,4.68,0,0,0,3.15,1.59c1.46.11,2.91.41,4.36.61.74.1,1.49.18,2.23.26l7.6.7c.23,0,.46,0,.69,0l8.31.44c2.06.1,4.11.19,6.17.26,3.3.11,6.6.25,9.9.29,4,.06,8,0,12,0,3.65,0,7.31-.14,11-.2,2.71,0,5.42,0,8.13-.07,2.41-.07,4.81-.25,7.22-.37,1.37-.07,2.74-.13,4.11-.21a22.08,22.08,0,0,0,2.44-.19q5.64-.93,11.28-1.91a76.14,76.14,0,0,0,7.9-1.86,60.36,60.36,0,0,0,6.09-2.16,42.65,42.65,0,0,0,4.67-2.25,6.6,6.6,0,0,0,3.13-4,35,35,0,0,0,1.05-4.36c.45-2.27.83-4.56,1.25-6.83.22-1.22.47-2.44.68-3.66a6.49,6.49,0,0,1,1.09-2.69,9.28,9.28,0,0,1,3.07-2.84c.57-.32,1.11-.69,1.68-1a4.68,4.68,0,0,0,1.55-1.61,1.17,1.17,0,0,0,.19-.54c.1-1.59.23-3.19.26-4.78,0-1.3-.08-2.61-.13-3.91a2,2,0,0,0-.15-.43c-1.1.43-2.17.79-3.2,1.25a60.32,60.32,0,0,1-7.39,2.56c-3.87,1.17-7.79,2.17-11.73,3.06-3.12.7-6.26,1.23-9.41,1.76a41.69,41.69,0,0,1-4.85.59C125.89,109.94,125.6,110,125.22,110.07Z"/>
          <path fill={theme} d="M0,100.09c1.24.35,2.47.73,3.72,1s2.41.55,3.61.82a1.05,1.05,0,0,1,.36.15,9.14,9.14,0,0,0,3.46,1c1.21.26,2.44.39,3.66.61,2.08.39,4.16.81,6.24,1.21s4.15.82,6.24,1.21c2.53.46,5.07.91,7.6,1.35,1.13.2,2.25.37,3.38.54,1.48.22,3,.46,4.45.61,1,.1,2,0,3,.07a2.6,2.6,0,0,0,.08-1.36c-.68-.12-1.35-.2-2-.35-1.84-.39-3.68-.82-5.53-1.2-1.05-.22-2.12-.38-3.18-.54-2.87-.43-5.74-.84-8.61-1.26L20,103c-.84-.12-1.67-.3-2.51-.43-1.58-.23-3.16-.42-4.74-.67-2.7-.43-5.39-.9-8.09-1.36-1-.17-1.92-.39-2.88-.55A3.33,3.33,0,0,0,0,100.09Z"/>
          <path fill={theme} d="M125.72,108.21c-.08.38-.15.72-.24,1.17.37,0,.66,0,.94,0,1.49-.17,3-.3,4.46-.55,2.76-.46,5.54-.89,8.27-1.52a69.17,69.17,0,0,0,6.93-1.67c1.08-.37,2.21-.55,3.31-.85s2.06-.63,3.09-.94a54.33,54.33,0,0,0,5.65-1.9l4-1.63a5.69,5.69,0,0,0,.64-.37c0-.49,0-1,0-1.56-.45.18-.84.32-1.22.48-1.93.81-3.89,1.51-5.88,2.14-1.15.36-2.31.71-3.46,1-1.66.49-3.32,1-5,1.46-2.83.78-5.67,1.56-8.55,2.14-2.27.45-4.52,1-6.78,1.47-1.6.34-3.2.64-4.8.93C126.7,108.15,126.27,108.16,125.72,108.21Z"/>
          <path fill={theme} d="M85.76,99.2l.18,1,4.49-.17,2.77-.1L99,99.81l3.44-.06c1.77,0,3.54-.1,5.31-.17a9.88,9.88,0,0,0,1.53-.21c1.84-.34,3.69,0,5.53,0,1.65,0,3.31-.11,5-.15,1,0,1.92,0,2.87,0,1.15,0,2.29.16,3.43.21,1.81.07,3.61.12,5.42.15,2.48,0,5,0,7.43,0,1.36,0,2.71-.27,4.06-.32,1.52-.06,3,0,4.55,0a7.06,7.06,0,0,0,1.87-.21,11.33,11.33,0,0,0-1.27-.34,13.35,13.35,0,0,0-1.54-.14c-.48,0-1,0-1.45,0-.8,0-1.6.1-2.4.1-1.06,0-2.12-.07-3.18-.08-.55,0-1.1.11-1.65.1-3-.06-6.05-.15-9.08-.2-2.44,0-4.88,0-7.32,0-2,0-4,.05-6,.07-2.51,0-5,.1-7.53.26a25.79,25.79,0,0,1-5,0,4.06,4.06,0,0,0-.77.06,10.32,10.32,0,0,1-2.42,0,17.1,17.1,0,0,0-2.86,0,6.86,6.86,0,0,1-1.1,0,36.42,36.42,0,0,0-6.18.15,3.83,3.83,0,0,1-.88.06A10.15,10.15,0,0,0,85.76,99.2Z"/>
          <path fill={theme} d="M34.62,100c2.09.17,4.18.36,6.27.49,1.18.07,2.36,0,3.54,0,4.68,0,9.37-.09,14.06-.09,4.43,0,8.85,0,13.28.08,2.81,0,5.62,0,8.43,0,.4,0,.8.09,1.21.09a11.5,11.5,0,0,0,1.17-.08V99.15c-.68-.05-1.29-.12-1.91-.13-2.13,0-4.26-.08-6.39-.11h-3c-2.25,0-4.49.12-6.73.34-1.32.13-2.66,0-4,.07l-5.64.15-.67,0a71.81,71.81,0,0,0-8.29.42c-1,.09-2,0-3,0-.66,0-1.33.08-2,0-1.66-.07-3.31-.2-5-.29-.46,0-.92,0-1.38,0Z"/>
        </g>
      </g>
    </svg>
  )
}

export default IconLargerShip