import React from "react"

const VideoPlayer = ({ title, posterURL, mp4URL, webmURL, options}) => (
  <div className="video embed-container">
    <video poster={posterURL} controls preload="auto">
      <source src={mp4URL} type="video/webm" />
      <source src={webmURL} type="video/mp4" />
    </video>
  </div>
)

export default VideoPlayer