import React from "react"
import IconLargerShip from "./IconLargerShip"

const SailForth = ({ url, titleshort, theme }) => {

  let themeOverride

  if (theme) {
    themeOverride = {
      color: theme,
    }
  }

  return (
    <a
      className="sail-forth"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={`Sail forth to ${titleshort}`}
      aria-label={`Sail forth to ${titleshort}`}
      style={themeOverride}
    >
      <IconLargerShip theme={theme} />
      <strong>Sail forth to {titleshort}</strong>
    </a>
  )
}

export default SailForth