import React from "react"

const IconDolphinPort = ({ theme }) => {
  if (!theme) {
    theme = "#00558b"
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.89 85.72" width="50px">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill={theme} d="M66.76,63.48c-.48-1-.85-1.9-1.36-2.73a15.61,15.61,0,0,1-2-5c-.18-.89-.45-1.76-.54-2.66a7.73,7.73,0,0,1,.75-4.79,4.94,4.94,0,0,0,.31-1.21c.85,0,1.7,0,2.56-.08,2.2-.11,4.41-.2,6.62-.36,3.72-.26,7.45,0,11.17.12,2.6.06,5.17.37,7.76.63a56.58,56.58,0,0,1,8.21,1.3c4.62,1.17,9.27,2.25,13.62,4.32a50.72,50.72,0,0,1,9.55,5.78c.41.32.77.7,1.15,1a.78.78,0,0,1,.15,1c-.25.46-.46.94-.68,1.41a17.16,17.16,0,0,0-.76,11.44c.93,3.92,3.23,7,5.79,9.86.62.7,1.27,1.38,2,2.14.12-1.79.24-3.45.34-5.11A32.21,32.21,0,0,1,134,70.31a14.53,14.53,0,0,0,1.57-5.59c0-.47.39-.59.83-.35a18.34,18.34,0,0,0,1.82.93c1.93.79,3.88,1.52,5.8,2.29a44.31,44.31,0,0,0,9.63,2.17c1.06.17,2.11.41,3.29.64a7.33,7.33,0,0,0-.33-.78,41.41,41.41,0,0,0-4.17-6.7,27.77,27.77,0,0,0-5.81-5.33c-1.46-1-2.84-2.13-4.31-3.11a17.85,17.85,0,0,0-6.83-2.73c-1.66-.28-3.28-.8-4.91-1.22a3.1,3.1,0,0,1-1.62-1.35c-1.29-1.62-2.71-3.15-4.07-4.71-1.51-1.73-3-3.52-4.53-5.19s-3.29-3.45-5-5.05c-2.05-1.86-4.17-3.65-6.35-5.34-3.8-2.95-7.59-5.92-12-8-1.56-.75-3.14-1.46-4.72-2.18a1.78,1.78,0,0,1-1.15-1.65,10.43,10.43,0,0,1,.52-4.34A28.17,28.17,0,0,1,93,9.05a35.66,35.66,0,0,1,2.18-3.66c.52-.8,1.14-1.53,1.71-2.29a2.7,2.7,0,0,0,.34-.56C97.83,1,97.66.46,96.06.13A4.06,4.06,0,0,0,94.51,0a13.29,13.29,0,0,0-4.44,1.39,59.26,59.26,0,0,0-7.72,5.06,66.06,66.06,0,0,0-6.68,6.16,1.53,1.53,0,0,1-1.64.4c-1.61-.37-3.21-.78-4.83-1-4.15-.65-8.31-1.24-12.5-1.5-2.77-.16-5.53-.46-8.29-.46-2.59,0-5.18.34-7.76.54-2.07.16-4.15.25-6.2.53a33.15,33.15,0,0,0-4.94,1.06,73.53,73.53,0,0,0-7.25,2.45,29.33,29.33,0,0,0-7.94,4.86,14.08,14.08,0,0,0-4.19,7.05c-.21.7-.28,1.44-.52,2.13a2,2,0,0,1-.73.86c-1.13.8-2.3,1.55-3.45,2.32A13.69,13.69,0,0,0,.75,36.28,7.23,7.23,0,0,0,0,38.47,1.22,1.22,0,0,0,1.09,40a6.15,6.15,0,0,0,1.42.12c2.82,0,5.64-.1,8.44.07s5.78.57,8.66,1c4.54.63,9.08,1.32,13.62,2Q38.5,44,43.73,45c1.23.24,2.41.72,3.61,1.07.62.18.69.69.84,1.2a19.41,19.41,0,0,0,3.32,7A25.87,25.87,0,0,0,59,60.89q2.33,1.29,4.76,2.41A3.86,3.86,0,0,0,66.76,63.48Z"/>
        </g>
      </g>
    </svg>
  )
}

export default IconDolphinPort