import React from "react"

const Company = ({ name, url, logoURL }) => (
  <div key={name}>
    <a className="company-logo" href={url} rel="noreferrer" target="_blank">
      <img src={logoURL} alt={name} />
    </a>
  </div>
)

export default Company